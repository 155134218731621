import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Scratch Render`}</h1>
    <p>{`Scratch render is a relatively new process that originated from the continent around ten years ago. It is a coloured cement based render with a surface that looks similar to weathered stone when finished. Scratch render is applied in such a manner that the elevation of a house is made to look plaster flat unlike normal sand and cement render. The surface of the render is then ‘scratched’ with a nail float.`}</p>
    <p>{`A big bonus of choosing a scratch render application for your home is that the colour is never damaged and can be power washed back to brand new after years of use.`}</p>
    <p>{`Scratch Render has significant insulation and waterproof properties that you will not find in traditional renders. Apart from being very hardwearing we feel scratch render gives a superior quality finish and when properly applied looks extremely attractive, adding value to your home.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      